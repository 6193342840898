/* Container for the entire form */
.form-Container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
  font-family: "Poppins", sans-serif;
  background: linear-gradient(115deg, #56d8e4 10%, #9f01ea 90%);
}
.pd-top{
  margin-top: -2rem !important;
}

/* Inner container for form content */
.form-inner-container {
  max-width: 500px;
  width: 100%;
  background: #fff;
  padding: 30px 35px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  transition: all 0.3s ease;
}

.form-inner-container:hover {
  transform: translateY(-5px);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3);
}

/* Form title styling */
.form-title {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  background: -webkit-linear-gradient(
    right,
    #56d8e4,
    #9f01ea,
    #56d8e4,
    #9f01ea
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 25px;
}

/* Alert messages styling */
.alert {
  text-align: center;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
}

.alert.error {
  background-color: #f8d7da;
  color: #721c24;
}

.alert.success {
  background-color: #d4edda;
  color: #155724;
}

/* Form content styling */
.form-content {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}

/* Form group styling */
.form-group {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 10px;
  font-weight: 600;
  margin-left: 10px;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.input-wrapper input {
  width: 100%;
  padding: 12px 12px 12px 40px;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.input-wrapper input:focus {
  border-color: #56d8e4;
  outline: none;
}

.input-wrapper .input-icon {
  position: absolute;
  left: 10px;
  color: #bbb;
}

/* Button styling */
.submit-button {
  width: 50%;
  padding: 14px 0;
  border-radius: 8px;
  background: linear-gradient(115deg, #56d8e4, #9f01ea);
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

.submit-button:hover {
  background: linear-gradient(115deg, #9f01ea, #56d8e4);
  transform: translateY(-3px);
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .form-inner-container {
    padding: 25px 20px;
  }

  .form-title {
    font-size: 28px;
  }
}

.register-link {
  margin-top: 30px;
  text-align: center;
}

.register-link a {
  color: #007bff;
  text-decoration: none;
}

.register-link a:hover {
  text-decoration: underline;
}
