.card {
  width: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin: 20px;
}

.card img {
  width: 100%;
  height: 200px;
  border-radius: 5px 5px 0 0;
}

.card-body {
  padding: 10px;
}

.card-title {
  color: navy;
  font-size: 18px;
  margin-bottom: 5px;
}
.imageGrid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  width: 100%;
}
