.image-section {
  position: relative;
  text-align: center;
  background-color: rgb(236, 229, 221);
  padding: 20px; /* Added padding for better spacing on smaller screens */
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  z-index: 1;
}

.main-image {
  width: 50%;
  height: 60vh;
  border-radius: 10px;
  position: relative;
  z-index: 2;
}

.image-description {
  position: absolute;
  /* bottom: 10px;
  left: 50%; */
  /* transform: translateX(-50%); */
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px 20px;
  border-radius: 20px;
  z-index: 3;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  position: relative;
  z-index: 3;
}

.navigation-buttons button {
  padding: 10px 20px;
  border: none;
  background-color: #ff6f61;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.navigation-buttons button:hover {
  background-color: #e65c50;
}

/* Media Queries */
@media (max-width: 1024px) {
  .main-image {
    width: 70%;
    height: 60vh;
  }

  .navigation-buttons {
    flex-direction: column;
    align-items: center;
  }

  .navigation-buttons button {
    width: 100%;
    margin-bottom: 10px;
  }

  .navigation-buttons button:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .main-image {
    width: 80%;
    height: 50vh;
  }

  .image-description {
    padding: 8px 16px;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 110px;
  }
}

@media (max-width: 480px) {
  .main-image {
    width: 90%;
    height: 35vh;
  }

  .image-description {
    padding: 6px 12px;
    font-size: 12px;
    margin-bottom: 110px;
  }

  .navigation-buttons button {
    padding: 8px 16px;
    font-size: 14px;
  }
}
