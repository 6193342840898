.comparison-table {
    text-align: center;
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  
  .table-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    border: 1px solid #ccc;
  }
  
  .table-header {
    display: contents;
    background: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
  }
  
  .table-row {
    display: contents;
  }
  
  .table-cell {
    padding: 16px;
    border: 1px solid #ccc;
    background: linear-gradient(to right, #e0eafc 0%, #cfdef3 100%);
    position: relative;
    transition: background 0.3s ease;
  }
  
  .table-cell:first-child {
    font-weight: bold;
    background: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
  }
  
  .table-cell::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .table-cell:hover::before {
    opacity: 1;
  }
  
  .table-cell:hover {
    background: linear-gradient(to right, #bdc3c7 0%, #2c3e50 100%);
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .table-container {
      grid-template-columns: 1fr 1fr;
    }
    .table-header {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media (max-width: 768px) {
    .table-container {
      grid-template-columns: 1fr;
    }
    .table-header {
      grid-template-columns: 1fr;
    }
    .table-cell {
      text-align: left;
    }
  }
  