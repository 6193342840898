.filters-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 300px;
  /* background-color: antiquewhite; */
}

.filter {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

select {
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.DesingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  /* padding: 1rem; */
  box-sizing: border-box;
  background-color: rgb(239, 239, 229);
  
}
.relatedOptions {
  width: 100%; /* Change from 100vw to 100% to fit within the parent container */
  height: auto; /* Change from 100vh to auto for dynamic height adjustment */
  display: grid;
  margin-top: 10rem;
  grid-template-columns: repeat(3, 1fr); /* Using repeat for better control */
  align-items: center;
  grid-gap: 1rem; /* Optional: Adds space between grid items */
  padding: 1rem; /* Optional: Adds padding inside the container */
  box-sizing: border-box; /* Ensures padding and border are included in width/height */
}

/* Media Queries */
@media (max-width: 1024px) {
  .relatedOptions {
    grid-template-columns: repeat(2, 1fr); /* Shows two items per row on medium screens */
  }
}

@media (max-width: 768px) {
  .relatedOptions {
    grid-template-columns: 1fr; /* Shows one item per row on smaller screens */
  }
}


.filterOptions {
    width: 100vw;

  display: grid;
  grid-template-columns: 10rem 1fr 1fr 1fr 1fr 10rem;
  align-items: center;
  justify-content: start;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.submitBtn {
  margin-top: 1.5rem;
  font-size: 1rem;
  border-radius: 8%;
  border: none;
  width: 5rem;
  height: 2.5rem;
  background-color: orange;
}

/* Media Queries */
@media (min-width: 600px) {
  .filters-container {
    max-width: 500px;
  }

  .filterOptions {
    max-width: 500px;
  }
}

@media (min-width: 768px) {
  .filters-container {
    max-width: 700px;
  }

  .filterOptions {
    max-width: 700px;
  }
}

@media (min-width: 1024px) {
  .filters-container {
    max-width: 900px;
  }

  .filterOptions {
    max-width: 900px;
  }
}

  
  /* Responsive styli
ng */
  @media (min-width: 600px) {
    .filters-container {
      flex-direction: row;
      justify-content: space-between;
    }
    .filter {
      flex: 1;
      margin-right: 1rem;
    }
    .filter:last-child {
      margin-right: 0;
    }
  }

  @media(max-width:600px)
  {
    .filterOptions{
        grid-template-columns: 1fr;

    }
    .filterOptions select{
        margin-top: 7rem;
        margin-bottom: 7rem;
    }
  }
  
  /* Base styles for the container and filter elements */
.filters-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 100%; /* Ensure it does not overflow the viewport width */
  margin: 0 auto;
  padding: 1rem; /* Added padding for better spacing */
  box-sizing: border-box; /* Include padding in width calculation */
}

.filter {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

select {
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

/* Styling and layout for the design container */
.DesignContainer {
  display: flex;
  align-items: center;
  justify-content: center; /* Centering content horizontally and vertically */
  flex-direction: column;
  width: 100%;
  padding: 1rem;
}

/* Grid layout for related options */
.relatedOptions {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns in larger screens */
  gap: 1rem; /* Spacing between grid items */
}

/* Responsive adjustments for .relatedOptions */
@media (max-width: 1024px) {
  .relatedOptions {
    grid-template-columns: repeat(2, 1fr); /* 2 columns in medium screens */
  }
}

@media (max-width: 768px) {
  .relatedOptions {
    grid-template-columns: 1fr; /* 1 column in small screens */
  }
}

/* Adjusting the filter options grid */
.filterOptions {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Responsive grid columns */
  gap: 1rem; /* Spacing between grid items */
  justify-items: center; /* Center items in the grid cells */
}

/* Styles for the submit button */
/* .submitBtn {
  margin-top: 3rem;
  font-size: 1rem;
  border-radius: 8%;
  border: none;
  width: 5rem;
  height: 2.5rem;
  background-color: orange;
} */

/* Adjustments for smaller screens */
@media (max-width: 600px) {
  .filters-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .filter {
    flex: 1;
    margin-right: 1rem;
  }

  .filter:last-child {
    margin-right: 0;
  }

  .filterOptions {
    grid-template-columns: 1fr; /* Single column on small screens */
  }

  .filterOptions select {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
