/* src/components/SizeSelection.css */

.size-selection-container {
  padding: 20px;
  margin: auto;
  width: 100vw;
  height: 100vh;
  text-align: center;
  background-color: #64b1c4;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box; /* Ensure padding is included in width/height */
}

.size-selection-container h2 {
  margin-bottom: 20px;
}

.size-selection-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 50px;
  background: #64b1c4;
  box-shadow: 27px 27px 44px #4e8a99, -27px -27px 44px #7ad8ef;
  width: 60%;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  padding: 20px;
}

.image-and-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  width: 100%;
}

.select {
  background-color: #4e8a99;
  border: none;
  border-radius: 20px;
  font-weight: 50;
  padding-left: 2rem;
  padding-right: 2rem;
}

.opt {
  font-weight: 50;
}

.option {
  border: none;
}

.opt:hover {
  background-color: aliceblue;
  color: black;
}

.kitchen-image {
  width: 200px;
  height: auto;
  transition: transform 0.3s;
  margin-bottom: 20px; /* Add margin for better spacing */
}

.inputOptions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.option {
  margin-bottom: 20px;
}

.option .label {
  display: block;
  margin-bottom: 10px;
  font-size: 1.2em;
}

select {
  margin-right: 10px;
  padding: 8px;
  font-size: 1em;
}

.buttons {
  display: grid;

grid-template-columns: 1fr 1fr; 
  justify-content: space-between;
  margin-top: 20px;
  width: 10%;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.back-button, .next-button {
  padding: 10px 20px;
  border: none;
  background-color: #f8d7da;
  color: #721c24;
  cursor: pointer;
  
}

.back-button:hover, .next-button:hover {
  background-color: #ff9aa2;
  color: white;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .size-selection-content {
      width: 80%; /* Adjust width for medium screens */
  }

  .inputOptions {
      margin-left: 0; /* Remove margin for better alignment */
  }

  .buttons {
      width: 100%;
  }

  .back-button, .next-button {
      width: 45%; /* Adjust button width */
  }
}

@media (max-width: 480px) {
  .size-selection-container {
      padding: 10px;
  }

  .size-selection-content {
      width: 100%; /* Full width on small screens */
      padding: 10px;
  }

  .image-and-options {
      flex-direction: column; /* Stack elements vertically */
      align-items: center;
  }

  .kitchen-image {
      width: 150px; /* Adjust image size for small screens */
  }

  .option .label {
      font-size: 1em; /* Adjust label font size */
  }

  select {
      width: 100%; /* Full width select */
      margin-bottom: 10px; /* Add margin for spacing */
  }

  .buttons {
      flex-direction: column; /* Stack buttons vertically */
      width: 100%;
  }

  .back-button, .next-button {
      width: 100%;
      margin-bottom: 10px; /* Add margin for spacing */
  }
}
