.calculator-container {
    max-width: 500px;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  } 
  
  .calculator-container h2 {
    margin-bottom: 10px;
  }
  
  .scope-of-work {
    margin: 20px 0;
  }
  
  .work-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .quantity-controls {
    display: flex;
    align-items: center;
  }
  
  .quantity-controls button {
    background-color: #ff6f61;
    color: white;
    border: none;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .quantity-controls button:hover {
    background-color: #4caf50;
  }
  
  .Buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .backButton, .nextButton {
    background-color: #ff6f61;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .Back-button:hover, .nextButton:hover {
    background-color: #4caf50;
  }
  
  @media (max-width: 768px) {
    .calculator-container {
      width: 90%;
      padding: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .work-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .quantity-controls {
      margin-top: 5px;
    }
  
    .Buttons {
      flex-direction: column;
    }
  
    .backButton, .nextButton {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  