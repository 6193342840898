.main-layout {
    /* margin-top: 10%;
     */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    
  }
  .empty
  {
    width: 100%;
    height: 10%;
    margin-bottom: 10%;
  }
  .image-section {
    flex: 1 1 60%;
    margin-right: 20px;
  }
  
  .form-section {
    flex: 1 1 35%;
  }
  