.footer {
    background-color: #2b2b2b;
    color: #fff;
    padding: 40px 20px;
}

.top-section {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #444;
    padding-bottom: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.top-section div {
    text-align: center;
    max-width: 300px;
    margin: 10px;
}

.top-section h4 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.top-section p {
    font-size: 0.9em;
    line-height: 1.4;
}

.bottom-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.logo img {
    max-width: 150px;
    margin-bottom: 20px;
}

.links {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.column {
    margin: 10px 20px;
    flex: 1;
    min-width: 200px;
}

.column h4 {
    font-size: 1em;
    margin-bottom: 10px;
    color: #e91e63;
}

.column ul {
    list-style: none;
    padding: 0;
}

.column ul li {
    margin-bottom: 5px;
    font-size: 0.9em;
}

.column address {
    font-style: normal;
    font-size: 0.9em;
    line-height: 1.6;
}

.social-media {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: xx-large;
    
}

.social-media a {
    display: inline-block;
    margin: 0 5px;
    color: #fff;
}

.social-media img {
    width: 20px;
    height: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .top-section {
        flex-direction: column;
        align-items: center;
    }

    .bottom-section {
        flex-direction: column;
        align-items: center;
    }

    .links {
        flex-direction: column;
        align-items: center;
    }

    .column {
        margin: 10px 0;
        min-width: 100%;
    }
}

@media (max-width: 480px) {
    .top-section div {
        max-width: 90%;
    }

    .social-media {
        flex-direction: column;
    }

    .social-media a {
        margin: 5px 0;
    }
}
