.img{
    width:100%;
    height: 20%;
    overflow: hidden; /* Hide any overflow */
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: -1; */
    /* margin: 10%; */
}
.img img{
    box-sizing: border-box;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    /* z-index: -1; */
}
.features
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.itemList
{
    display: grid;
    grid-template-columns:1fr  1fr  1fr 1fr ;
    justify-content: space-between;
}
.cards{
    margin: 3%;
    background-color: rgb(169, 214, 214);
    display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.scrollBar
{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(233, 227, 219);
}
@media(max-width:480px){
.itemList
{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    /* background-color: aqua; */
}
.h1{
    font-size: 20px;
}
}
@media(max-width:820px ) and (min-width:481px){
.itemList
{
    display: grid;
    grid-template-columns:1fr  1fr  1fr ;
    justify-content: space-between;
    /* background-color: aqua; */
}
}

